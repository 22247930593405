const MAX = 8
const TIMER = 2000

const setup = () => {
  let current = 0
  let previous
  let started = 0

  const items = [...document.querySelectorAll('.header-slide')]

  const next = () => {
      if(started === 1) {
        previous = current

        current = current === MAX
          ? 0
          : current + 1
          
        update()
      }
    
      if(Number(localStorage.getItem("slide_pos")) === 2) {
        started = 1;
      }
  }
  const update = () => window.requestAnimationFrame(() => {
    items[previous].classList.remove('is-active')
    items[current].classList.add('is-active')
  })

  setInterval(next, TIMER)
}

export default setup
