import setupCallout from './sections/callout.js'
import setupHeader from './sections/header.js'
import setupInview from './providers/inview.js'

if (module.hot) {
  module.hot.accept()
}

const boot = event => {
  setupCallout()
  setupHeader()
  setupInview()
}
document.addEventListener('DOMContentLoaded', boot)
