import jump from 'jump.js'
import loadVideo from '../utils/load-video.js'
import viewport from '../providers/viewport.js'

const sources = {
  small: new URL('../../assets/videos/wanderlab-small.mp4', import.meta.url).pathname,
  medium: new URL('../../assets/videos/wanderlab-medium.mp4', import.meta.url).pathname,
  large: new URL('../../assets/videos/wanderlab-large.mp4', import.meta.url).pathname
}

const setup = async () => {
  // cache breakpoint
  const mobile = viewport.width < 768
  const tablet = viewport.width >= 768 && viewport.width < 1024
  const desktop = viewport.width >= 1024

  // cache nodes
  const header = document.querySelector('.header')
  const video = header.querySelector('.header-video')
  // const arrow = header.querySelector('.header-arrow')

  // bind arrow click handler
  // const handleClick = event => {
  //   event && event.preventDefault()

  //   let offset

  //   if (mobile) offset = -80
  //   if (tablet) offset = -40
  //   if (desktop) offset = -90

  //   jump('.connect', { offset })
  // }

  // arrow.addEventListener('click', handleClick)

  // load video
  let src

  if (mobile) src = sources.small
  if (tablet) src = sources.medium
  if (desktop) src = sources.large

  const blob = await loadVideo(src)
  video.setAttribute('src', blob)
  // show video
  window.requestAnimationFrame(() => video.classList.add('is-loaded'))
}

export default setup
