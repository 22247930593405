import setupAbout from '../sections/about.js'
import setupCompany from '../sections/company.js'
import setupCompanySlide from '../sections/slide-company.js'

const OFFSET = 15

const setup = () => {
  let observer

  const nodes = [...document.querySelectorAll('[data-inview]')]

  const options = {
    root: null,
    rootMargin: `0px 0px -${ OFFSET }% 0px`,
    threshold: .01
  }

  const handleIntersect = entries => entries.forEach(entry => {
    if (!entry.isIntersecting) return

    window.requestAnimationFrame(() => entry.target.classList.add('is-inview'))

    switch (entry.target.getAttribute('data-init')) {
      case 'about':   setupAbout()   ; break
      case 'company': setupCompany() ; break
      case 'company_slide': setupCompanySlide() ; break
    }

    observer.unobserve(entry.target)
  })

  observer = new IntersectionObserver(handleIntersect, options)

  nodes.forEach(node => observer.observe(node))
}

export default setup
