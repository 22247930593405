const MAX = 27
const TIMER = 2000

const setup = () => {
  let current = 0
  let previous

  const items = [...document.querySelectorAll('.company-logo')]

  const next = () => {
    previous = current

    current = current === MAX
      ? 0
      : current + 1

    update()
  }

  const update = () => window.requestAnimationFrame(() => {
    items[previous].classList.remove('is-active')
    items[current].classList.add('is-active')
  })

  setInterval(next, TIMER)
}

export default setup
