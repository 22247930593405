const loadVideo = path => new Promise(resolve => {
  // create XHR request instance
  const request = new XMLHttpRequest()

  // bind event listener
  const handleLoad = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`Video loaded: ${ path }`)
    }

    // cache blob response
    const blob = request.response
    const src = window.URL.createObjectURL(blob)

    // resolve source blob
    resolve(src)
  }

  request.addEventListener('load', handleLoad)

  // set response type to blob
  request.responseType = 'blob'

  // send request
  request.open('GET', path)
  request.send()
})

export default loadVideo
