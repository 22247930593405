const setup = () => {
  const node = document.querySelector('.callout-letters')

  const letters = node
    .textContent
    .split('')

  const frag = document.createDocumentFragment()

  let delay = 0

  letters.forEach((letter, index) => {
    const span = document.createElement('span')

    if (letter !== ' ') {
      span.classList.add('callout-letter')
      span.style.transitionDelay = `${ delay * .05 }s`
      delay += 1
    } else {
      span.classList.add('callout-space')
    }

    span.textContent = letter
    frag.append(span)
  })

  node.innerHTML = ''
  node.append(frag)
}

export default setup
