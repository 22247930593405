const viewport = () => {
  let timer

  const cache = {}

  const capture = () => {
    cache.width = window.innerWidth
    cache.height = window.innerHeight
  }

  const handleResize = () => {
    clearTimeout(timer)
    timer = setTimeout(capture, 250)
  }

  capture()

  window.addEventListener('resize', handleResize)

  return cache
}

const singleton = viewport()

export default singleton
